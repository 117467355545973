.layoutContainer{
    width: 100%;
}

.main {
    width: 100%;
    max-width: 2500px;
    padding: var(--nav_height) 0 0 0;
  
    @media screen and (min-width: 550px) {
      padding: calc(var(--nav_height) + 0px) 0rem 0 0rem;
    }
  
    @media screen and (min-width: 730px) {
      padding: calc(var(--nav_height) + 0px) 0 0 0;
    }
  
    @media screen and (min-width: 1000px) {
      padding: calc(var(--nav_height) + 0px) 0 0 0;
    }
  
    @media screen and (min-width: 1200px) {
      padding: calc(var(--nav_height) + 0px) 0 0 0;
    }
  
    @media screen and (min-width: 1400px) {
      padding: calc(var(--nav_height) + 0px) 0 0 0;
    }
  
    @media screen and (min-width: 1700px) {
      padding: calc(var(--nav_height) + 0px) 0 0 0;
    }
  }