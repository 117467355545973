.homeContainer {
	width: 100%;
	max-width: 2500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	height: 100%;
	margin-top: 1.5rem;

	@media screen and (min-width: 730px) {
		padding: 0 2.33rem 0 2.33rem;
		margin-bottom: 2.66rem;
		margin-top: 3.31rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0 2.87rem 0 2.87rem;
		margin-bottom: 4.01rem;
		margin-top: 3.81rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0 8.25rem 0 8.25rem;
		margin-bottom: 6.04rem;
		margin-top: 4.75rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 16.25rem 0 16.25rem;
		margin-bottom: 6.3rem;
		margin-top: 8.12rem;
	}
}

.headerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	margin-bottom: 6rem;
	gap: 0.5rem;
	padding: 2.63rem 1.21875rem 2.62rem 1.28125rem;

	@media screen and (min-width: 730px) {
		margin-bottom: 4.07rem;
		gap: 1.42rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		margin-bottom: 5rem;
		gap: 1.75rem;
	}

	@media screen and (min-width: 1400px) {
		margin-bottom: 6.3rem;
		gap: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		margin-bottom: 7.5rem;
		gap: 6rem;
	}
}
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.25rem;

	@media screen and (min-width: 730px) {
		gap: 0.14rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.17rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.25rem;
	}
}
.heading {
	font-size: 2rem;
	color: #101116;
	font-weight: 700;

	span {
		background: linear-gradient(91deg, #b162ff 64.21%, #5869ff 82.96%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.subHeading {
	font-size: 1.09rem;
	color: #737784;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.25rem;
	}
}
.headerImg {
	display: flex;
	width: 100%;
	z-index: 1;
	img {
		width: 100%;
		height: 100%;
		// aspect-ratio: 0.78;

		@media screen and (min-width: 730px) {
			aspect-ratio: 2.21;
			// aspect-ratio: 1.91;
		}
	}
}

.mailFeatures_Container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 2.03rem;
	padding: 0 1.5rem;
	text-align: center;

	@media screen and (min-width: 730px) {
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}

.mailFeatures_header {
	font-size: 1.875rem;
	font-weight: 700;
	text-align: center;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.mail_Features_content {
	display: flex;
	flex-direction: column;
	padding-bottom: 1.08rem;
	gap: 0.5rem;

	@media screen and (min-width: 730px) {
		gap: 3.35rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		flex-direction: row;
	}

	@media screen and (min-width: 1000px) {
		gap: 4.12rem;
		padding-bottom: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.2rem;
		padding-bottom: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6.19rem;
		padding-bottom: 2rem;
	}
}
.featureContainer {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	@media screen and (min-width: 730px) {
		gap: 0.27rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.42rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.5rem;
	}
}
.mail_feature {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.75rem;
	border-radius: 1rem;
	background: rgba(67, 89, 246, 0.02);
	padding: 0.54rem 0.5rem;

	@media screen and (min-width: 730px) {
		gap: 0.41rem;
		padding: 0.54rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.5rem;
		padding: 0.67rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.63rem;
		padding: 0.84rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.75rem;
		padding: 1rem;
	}
}
.featureIcons {
	height: 1.42rem;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
		height: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		height: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2.625rem;
	}
}
.featureDescription {
	font-size: 0.75rem;
	font-weight: 500;
	color: #101116;
	text-align: left;

	@media screen and (min-width: 730px) {
		font-size: 0.72rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.88rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.12rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.25rem;
	}
}
.downloadContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	background-color: #4359f6;
	gap: 2.62rem;
	padding: 2.88rem 1.12rem 5.37rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 5.89rem;
		padding: 1.29rem 0 0 3.32rem;
		border-radius: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		gap: 7.24rem;
		padding: 1.58rem 0 0 4.08rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 1.68rem;
		gap: 9.13rem;
		padding: 2rem 0 0 5.15rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 2rem;
		gap: 10.87rem;
		padding: 2.37rem 0 0 6.13rem;
	}
}
.downloadLeftContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2.62rem;

	@media screen and (min-width: 730px) {
		gap: 1.42rem;
		padding: 1.89rem 0 3.17rem 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.7rem;
		padding: 2.31rem 0 3.89rem 0;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.21rem;
		padding: 2.9rem 0 4.89rem 0;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
		padding: 3.5rem 0 5.87rem 0;
	}
}
.downloadHeader {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.76rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.73rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.25rem;
	}
}
.downloadImgContainer {
	display: flex;
	justify-content: center;
	width: 100%;

	img {
		width: 50%;
		aspect-ratio: 0.48;

		@media screen and (min-width: 730px) {
			width: 100%;
			aspect-ratio: 0.92;
		}
	}
}
.osLogoContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 2.5rem;

	@media screen and (min-width: 730px) {
		justify-content: flex-start;
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}

.download_Os_container {
	display: flex;
	flex-direction: column;
	// gap:

	@media screen and (min-width: 730px) {
		gap: 2.17rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4rem;
	}
}
.osLogos {
	height: 2rem;

	@media screen and (min-width: 730px) {
		height: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		height: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		height: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2rem;
	}
}
.downloadBtnContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.downloadBtn {
	border: none;
	background-color: black;
	outline: none;
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;

	@media screen and (min-width: 730px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 1.69rem;
		font-size: 0.81rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 2.08rem;
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 2.625rem;
		padding: 1.05rem 1.47rem;
		font-size: 1.26rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 3.125rem;
		padding: 1.25rem 1.75rem;
		font-size: 1.5rem;
	}
}
.mailContentContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 6rem;

	@media screen and (min-width: 730px) {
		margin-bottom: 5.76rem;
	}

	@media screen and (min-width: 1000px) {
		margin-bottom: 7.07rem;
	}

	@media screen and (min-width: 1400px) {
		margin-bottom: 8.93rem;
	}

	@media screen and (min-width: 1700px) {
		margin-bottom: 10.62rem;
	}
}
.illustrationContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2.62rem;
	padding: 0 0.63rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 3.76rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 4.62rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.83rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6.94rem;
	}
}
.illustrationText {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.illustrationHeader {
	font-size: 2rem;
	font-weight: 700;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.illustrationImage {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 1.12;
	}
}
.mailPrivacyContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 1.19rem;
	gap: 2.62rem;

	@media screen and (min-width: 730px) {
		gap: 2.03rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}

.illustrationImg {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 1.18;
	}

	@media screen and (min-width: 730px) {
		aspect-ratio: 1.18;
		width: 60%;
	}
}
.ilustrationImgContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.62rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 0.64rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 0.79rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 1.19rem;
	}
}
.illustrationImg1 {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 0.75;
	}

	@media screen and (min-width: 730px) {
		flex-direction: row;
		width: 39%;
	}
}
.setUsApartContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 0.69rem;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		padding: 0;
		gap: 2.37rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.91rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4.38rem;
	}
}
.setApartImg {
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		aspect-ratio: 0.54;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.32;
		}
	}
}
.ilustration4img {
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		aspect-ratio: 1.82;
	}
}
.footerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-end;
	background-color: #4359f6;
	padding: 2.13rem 1.5rem 4.87rem 1.5rem;
	gap: 9.25rem;

	@media screen and (min-width: 730px) {
		// padding: 0 0.4rem;
		padding: 0;
		gap: 1.01rem;
		flex-direction: row-reverse;
		width: 100%;
		justify-content: space-between;
		border-radius: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		// padding: 0 0.45rem;
		gap: 1.24rem;
	}

	@media screen and (min-width: 1400px) {
		// padding: 0 0.56rem;
		border-radius: 1.68rem;
		gap: 1.74rem;
	}

	@media screen and (min-width: 1700px) {
		// padding: 0.65rem;
		border-radius: 2rem;
		gap: 1.87rem;
	}
}
.footerLeftContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;
	gap: 2.62rem;
	width: 100%;
	z-index: 2;

	@media screen and (min-width: 730px) {
		align-items: flex-start;
		width: 50%;
		gap: 1.42rem;
		padding: 2.37rem 1.37rem 3.93rem 0;
	}

	@media screen and (min-width: 1000px) {
		padding: 2.91rem 1.68rem 4.83rem 0;
		gap: 1.7rem;
	}
	@media screen and (min-width: 1200px) {
		width: 65%;
	}

	@media screen and (min-width: 1400px) {
		width: 50%;
		padding: 3.8rem 2.12rem 6.1rem 0;
		gap: 2.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
		padding: 4.37rem 2.53rem 7.38rem 0;
	}
}
.footerText_header {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.76rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.73rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.25rem;
	}
}
.footerImgContainer {
	display: flex;
	align-items: flex-end;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 1.41;
		z-index: 0;

		@media screen and (min-width: 730px) {
			width: 100%;
		}
	}
	@media screen and (min-width: 730px) {
		width: 50%;
	}
	@media screen and (min-width: 1200px) {
		width: 35%;
	}
	@media screen and (min-width: 1400px) {
		width: 50%;
	}
	@media screen and (min-width: 1700px) {
		width: 50%;
	}
}
.footerLogoContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 2.5rem;

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}

.footerDownload_container {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 730px) {
		gap: 2.17rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4rem;
	}
}

.footerBtn_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.FooterDownloadBtn {
	border: none;
	background-color: black;
	outline: none;
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;

	@media screen and (min-width: 730px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 1.69rem;
		font-size: 0.81rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 2.08rem;
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 2.625rem;
		padding: 1.05rem 1.47rem;
		font-size: 1.26rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 3.125rem;
		padding: 1.25rem 1.75rem;
		font-size: 1.5rem;
	}
}
.illustrationImg2_container {
	display: flex;
	flex-direction: column;
	gap: 1.19rem;
	width: 100%;

	@media screen and (min-width: 730px) {
		width: 60%;
	}
}

.illustrationImg4_1 {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		height: 100%;
		aspect-ratio: 0.7;
	}

	@media screen and (min-width: 730px) {
		width: 39%;
	}
}
.illustrationImg4_2 {
	display: flex;
	width: 100%;
	img {
		width: 100%;
		aspect-ratio: 2.26;

		@media screen and (min-width: 730px) {
			aspect-ratio: 2.26;
		}
	}

	@media screen and (min-width: 730px) {
		width: 100%;
	}
}
.privacyContent {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 2rem;
	justify-content: center;
	gap: 1.77rem;

	img {
		width: 100%;
		aspect-ratio: 1;
	}
}
.privacyContainer1 {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	width: 100%;
	gap: 1.5rem;

	@media screen and (min-width: 730px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-content: flex-start;
		gap: 0.81rem;
		width: 100%;
	}

	@media screen and (min-width: 1000px) {
		gap: 1rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.39rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.5rem;
	}
}
.mailFeature1Header {
	font-weight: 500;
}
.simplifiedPrivacyContainer {
	display: flex;
	width: 100%;
	padding: 0 2.12rem;

	@media screen and (min-width: 730px) {
		padding: 0;
	}
}
.simplifiedPrivacyChild {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2.62rem;

	@media screen and (min-width: 730px) {
		gap: 3.25rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 4rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 5.04rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 6rem;
	}
}
.mailFeatureSubHeader {
	font-size: 1.25rem;
	font-weight: 500;
	color: #444343;
	text-align: center;

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.625rem;
	}
}
